<template>
  <div>
    <el-dialog title="签署协议" :visible.sync="dialogVisible" :close-on-click-modal="false" width="760px"
      :destroy-on-close="true" :append-to-body="true">
      <div class="dia-content">
        <!-- 步骤条 -->
        <VfSteps ref="stepRef" :activeStep="activeStep"></VfSteps>
        <section v-show="activeStep === 0">
          <!-- 个人签署协议 -->
          <personalSign ref="personalSignRef" v-if="goodType == 1" :subjectList="subjectList" :vipInfo="vipInfo"
            @stepAdd="stepAdd" @success="handleDownLoadFile"></personalSign>
          <!-- 企业签署协议 -->
          <firmSign ref="firmSignRef" v-if="goodType == 2" :vipInfo="vipInfo" :AuthorizedList="AuthorizedList"
            :NoAuthorizedList="NoAuthorizedList" @stepAdd="stepAdd" @success="handleDownLoadFile"></firmSign>
        </section>
        <section class="iframe-wrap" v-show="activeStep === 1">
          <iframe :src="pdfUrl" frameborder="0"></iframe>
          <el-radio v-model="signatureConfirmation" :label="true">点击确认签署按钮及代表您同意上述协议条款并签署协议</el-radio>
        </section>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" v-if="activeStep === 0">取消</el-button>
        <el-button v-if="activeStep === 1" @click="activeStep = 0">上一步</el-button>
        <el-button type="primary" @click="nextStep" v-if="activeStep === 0">下一步</el-button>
        <el-button type="primary" v-if="activeStep === 1" @click="confirm">确认签署</el-button>
      </span>
    </el-dialog>
    <!-- pdf -->
    <PurchaseProtocol v-if="vipInfo && vipInfo.contract_id && vipInfo.contract_id !== ''" ref="PurchaseProtocol" />
  </div>
</template>

<script>
import VfSteps from '../components/steps.vue'
import personalSign from '../components/personalSign.vue'
import firmSign from '../components/firmSign.vue'
import { fetchUserList, fetchUserVipInfo } from '@/api/user'
import PurchaseProtocol from '@/components/VfPdf/PurchaseProtocol'
import { subscriptionServiceAgreementQY } from '@/config'
import { fetchMemberContractPdf } from '@/api/member'
export default {
  components: {
    VfSteps,
    personalSign,
    firmSign,
    PurchaseProtocol
  },
  watch: {
    dialogVisible(v) {
      if (!v) {
        this.activeStep = 0
        this.signatureConfirmation = false
        this.clearFormFn()
      }
    }
  },
  data() {
    return {
      pdfUrl: subscriptionServiceAgreementQY,
      dialogVisible: false,
      goodType: '',
      order_id: '',
      activeStep: 0,
      signatureConfirmation: false,
      vipInfo: null,
      NoAuthorizedList: [],
      AuthorizedList: [],
      subjectList: [],
      clearForm: {
        name: '',
        id_card: '',
        address: '',
        legal_name: '',
        phone: ''
      },
    };
  },
  methods: {
    showDia(type, order_id) {
      this.goodType = type
      console.log(type)
      this.order_id = order_id
      this.dialogVisible = true
      this.getList()
      this.UserVipInfo()
    },
    clearFormFn() {
      const firmSignRef = this.$refs.firmSignRef
      const personalSignRef = this.$refs.personalSignRef
      if (firmSignRef) {
        firmSignRef.ruleForm = JSON.parse(JSON.stringify(this.clearForm))
        firmSignRef.conuser_id = ''
      }
      if (personalSignRef) {
        personalSignRef.ruleForm = JSON.parse(JSON.stringify(this.clearForm))
      }
    },
    // 确认签署
    confirm() {
      if (!this.signatureConfirmation) {
        this.$message.warning('请先勾选同意上述协议条款并签署协议按钮')
        return
      }
      if (this.goodType === 1) {
        this.$refs.personalSignRef.handleSubmitInfo()
      } else {
        this.$refs.firmSignRef.handleSubmitInfo()
      }
    },
    async handleDownLoadFile(info) {
      await this.UserVipInfo()
      // this.$nextTick(() => {
      //   this.$refs.PurchaseProtocol.initPDFPage(info.contract_id)
      // })
      await fetchMemberContractPdf(info.contract_id)
      this.dialogVisible = false
      this.$emit('getVipsList', info.type)
    },
    nextStep() {
      const firmSignRef = this.$refs.firmSignRef
      const personalSignRef = this.$refs.personalSignRef
      if (this.goodType === 2) {
        // 已有企业tab
        if (firmSignRef.activeName === 'first') {
          if (!firmSignRef.conuser_id) {
            this.$message.warning('请先选择企业')
          } else {
            this.stepAdd()
          }
        } else {
          // 添加新企业tab
          firmSignRef.submitForm()
        }
      } else {
        personalSignRef.submitForm()
      }
    },
    stepAdd() {
      this.activeStep = 1
    },
    // 获取会员信息
    async UserVipInfo() {
      try {
        const res = await fetchUserVipInfo({
          order_id: this.order_id
        })
        if (res.code === 0) {
          this.vipInfo = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取授权企业(is_contract=1表示已授权)
    async getList() {
      try {
        const res = await fetchUserList({
          type: this.goodType,
          order_id: this.order_id
        })
        if (res.code === 0) {
          this.subjectList = res.data
          // 未授权公司
          this.NoAuthorizedList = res.data.filter(m => m.is_contract === 0)
          // 已授权公司
          this.AuthorizedList = res.data.filter(m => m.is_contract === 1)
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  .el-dialog__header {
    padding: 24px 36px 12px;
    border-bottom: 1px solid #F5F6FA;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }

  .el-dialog__body {
    padding: 19px 60px 40px;
  }

  .el-dialog__footer {
    .el-button {
      height: 36px;
      border-radius: 2px;
      border: none;
      font-weight: 400;
      font-size: 14px;
    }

    .el-button--default {
      background: #F2F2F2;
      color: rgba(0, 0, 0, 0.5);
    }

    .el-button--primary {
      background: #E52A0D;
    }
  }

  iframe {
    width: 100%;
    height: 400px;
    margin: 10px 0;
  }

  .iframe-wrap {
    .el-radio__label {
      font-size: 12px;
    }
  }
}
</style>