<template>
  <div class="sign-wrap">
    <p class="tip"><i class="el-icon-warning-outline"></i>为了保证您的权益得到保障，请务必填写真实信息</p>
    <ul class="vip-info" v-if="vipInfo">
      <li>会员名称：{{ `个人${vipInfo.goods_name}` }}</li>
      <li>会员有效期：{{ vipInfo.start_at.replace(/-/g, '/') | parseDate('yyyy-MM-dd') }} 至
        {{ vipInfo.end_at.replace(/-/g, '/') | parseDate('yyyy-MM-dd') }}</li>
    </ul>
    <el-form :model="ruleForm" :rules="rules" @submit.native.prevent ref="ruleForm" label-width="90px">
      <el-form-item label="姓名" prop="name">
        <el-input :disabled="formDis" v-model="ruleForm.name" maxlength="20" placeholder="请输入您的姓名"></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="id_card">
        <el-input :disabled="formDis" v-model="ruleForm.id_card" placeholder="请输入您的身份证号码"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input :disabled="formDis" maxlength="11" v-model="ruleForm.phone" placeholder="请输入您的手机号"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input :disabled="formDis" v-model="ruleForm.email" placeholder="请输入您的邮箱"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input :disabled="formDis" v-model="ruleForm.address" maxlength="100" placeholder="请输入您的详细地址"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { authInfoForPeople } from '@/api/member'
import { email_rule, phoneNumber, IDNumber_dl } from '@/utils/regular'
export default {
  props: {
    vipInfo: {
      type: Object,
      default: () => null
    },
    subjectList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const validateID = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入您的身份证号码'))
      } else if (!IDNumber_dl.test(value)) {
        callback(new Error('请输入正确的身份证号码'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        name: '',
        id_card: '',
        address: '',
        email: '',
        phone: ''
      },
      formDis: false,
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        id_card: [{ required: true, trigger: 'blur', validator: validateID }]
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    if (this.userInfo.phone || this.userInfo.email) {
      this.ruleForm.phone = this.userInfo.phone || ''
      this.ruleForm.email = this.userInfo.email || ''
    }
  },
  watch: {
    subjectList(value) {
      if (value && value.length > 0) {
        for (const key in this.ruleForm) {
          this.ruleForm[key] = value[0][key]
        }
        this.formDis = true
      } else {
        this.formDis = false
      }
    }
  },
  methods: {
    submitForm(formName = 'ruleForm') {
      if (this.formDis) {
        this.$emit('stepAdd')
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (!this.ruleFormItem()) {
              return
            }
            this.$emit('stepAdd')
          }
        });
      }
    },
    ruleFormItem() {
      let rule = true
      const { email, phone } = this.ruleForm
      if (email) {
        if (!email_rule.test(email)) {
          rule = false
          this.$message.error('邮箱填写格式有误')
        } else {
          rule = true
        }
      }
      if (phone) {
        if (!phoneNumber.test(phone)) {
          rule = false
          this.$message.error('手机号号填写格式有误')
        } else {
          rule = true
        }
      }
      return rule
    },
    async handleSubmitInfo() {
      const params = {
        order_id: this.vipInfo.order_id,
        type: this.vipInfo.goods_type,
        ...this.ruleForm
      }
      if (this.formDis) {
        params.conuser_id = this.subjectList[0].conuser_id
      }
      try {
        const res = await authInfoForPeople(params)
        if (res.code === 0) {
          this.$message.success('提交成功')
          this.$emit('success', {
            type: 1,
            contract_id: res.data.contract_id
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-wrap {
  .tip {
    @include size(100%, 32px);
    background: rgba(255, 142, 20, 0.1);
    border: 1px solid #FF8E14;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    padding-left: 24px;
    margin: 8px 0 24px;

    i {
      color: #FF8E14;
      margin-right: 4px;
    }
  }

  .vip-info {
    display: flex;
    margin-bottom: 24px;
    padding-left: 24px;

    li {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);

      &:first-child {
        margin-right: 40px;
      }
    }
  }

  /deep/.el-form {
    padding-left: 24px;

    .el-form-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .is-required {
      .el-form-item__label {
        padding-left: 0;
      }
    }

    .el-form-item__label {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      text-align: left;
      padding-left: 6.08px;
    }

    .el-form-item__content {
      height: 32px;
    }

    .el-input {
      height: 100%;

      input {
        height: 100%;
        background: #F5F6FA;
        border-radius: 2px;
        border: none;

        &::placeholder {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
</style>