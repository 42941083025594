// 纳税号正则
export const taxIdentificationNum = /^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
// 台湾身份证
export const IDNumber_tw = /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/;
// 港澳身份证
export const IDNumber_ga = /^([A-Z]\d{6,10}(\(\w{1}\))?)$/;
// 大陆身份证
export const IDNumber_dl = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
// 手机号
export const phoneNumber = /^[1]([3-9])[0-9]{9}$/;
// 邮箱
export const email_rule = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
