<template>
  <ul class="vf-steps">
    <li :class="activeStep === i ? 'step activeStep' : activeStep === 1 ? 'step zIndexMax' : 'step'" v-for="(m, i) in list"
      :key="i">{{
        m }}</li>
  </ul>
</template>

<script>
export default {
  props: {
    activeStep: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: ['确认基本信息', '签署协议']
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-steps {
  width: 100%;
  display: flex;

  .step {
    @include size(50%, 40px);
    @include center-middle-flex;
    background: #F2F2F2;
    margin-right: 5px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    position: relative;

    &:last-child {
      margin-right: 0;

      &::after {
        left: 0px;
        border-left-color: #FFFFFF;
      }
    }

    &::after {
      content: '';
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-left-color: #F2F2F2;
      position: absolute;
      right: -40px;
    }
  }

  .zIndexMax {
    &::after {
      z-index: 100;
    }
  }

  .activeStep {
    background: rgba(0, 97, 243, 0.2);

    &::after {
      z-index: 99;
      border-left-color: rgba(0, 97, 243, 0.2);
    }
  }
}
</style>