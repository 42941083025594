<template>
  <div class="sign-wrap">
    <ul class="vip-info" v-if="vipInfo">
      <li>会员名称：{{ `企业${vipInfo.goods_name}` }}</li>
      <li>会员有效期：{{ vipInfo.start_at.replace(/-/g, '/') | parseDate('yyyy-MM-dd') }} 至
        {{ vipInfo.end_at.replace(/-/g, '/') | parseDate('yyyy-MM-dd') }}</li>
      <li>可授权企业数：{{ vipInfo.cert_num }}</li>
      <li class="rowShow" v-if="AuthorizedList.length > 0">
        <span>已授权企业：</span>
        <p class="firmlist">
          <span class="name" v-for="item in AuthorizedList" :key="item.conuser_id">{{ item.name }}</span>
        </p>
      </li>
    </ul>
    <div class="tab-wrap">
      <div class="header">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="选择已有企业" name="first"></el-tab-pane>
          <el-tab-pane label="添加新企业授权" name="second"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="tab-content radio-container" v-if="activeName === 'first'">
        <el-radio-group v-model="conuser_id">
          <el-radio v-for="m in NoAuthorizedList" :key="m.conuser_id" :label="m.conuser_id">
            <span v-if="m.is_rel === 1">{{ m.name }}<i class="redText">(待签署)</i></span>
            <span v-else>{{ m.name }}</span>
          </el-radio>
        </el-radio-group>
        <p class="tip redText" v-if="NoAuthorizedList.length > 0">* 待签署表示当前会员该企业已激活授权书</p>
      </div>
      <div class="tab-content form-container" v-else>
        <p class="tip"><i class="el-icon-warning-outline"></i>为了保证您的权益得到保障，请务必填写真实信息</p>
        <el-form :model="ruleForm" :rules="rules" @submit.native.prevent ref="ruleForm" label-width="71px">
          <el-form-item label="企业名称" prop="name">
            <el-input v-model="ruleForm.name" maxlength="50" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="纳税号" prop="id_card">
            <el-input v-model="ruleForm.id_card" maxlength="50" placeholder="请输入企业纳税号"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="ruleForm.address" maxlength="100" placeholder="请输入您的详细地址"></el-input>
          </el-form-item>
          <el-form-item label="法人" prop="legal_name">
            <el-input v-model.trim="ruleForm.legal_name" maxlength="20" placeholder="请输入公司法人姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm.phone" maxlength="11" placeholder="请输入您的手机号"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { authInfoForCompany } from '@/api/member'
import { fetchAddContractUser } from '@/api/user'
import { taxIdentificationNum, phoneNumber } from '@/utils/regular'
export default {
  props: {
    vipInfo: {
      type: Object,
      default: () => null
    },
    NoAuthorizedList: {
      type: Array,
      default: () => []
    },
    AuthorizedList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeName: 'first',
      conuser_id: '',
      ruleForm: {
        name: '',
        id_card: '',
        address: '',
        legal_name: '',
        phone: ''
      },
      // 已有企业信息
      firmFormInfo: {
        name: '',
        id_card: '',
        address: '',
        legal_name: '',
        phone: '',
        conuser_id: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入您的详细地址', trigger: 'blur' }]
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submitForm(formName = 'ruleForm') {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.ruleFormItem()) {
            return
          }
          this.$emit('stepAdd')
        }
      });
    },
    ruleFormItem() {
      let rule = true
      const { id_card, phone } = this.ruleForm
      if (id_card) {
        if (!taxIdentificationNum.test(id_card)) {
          rule = false
          this.$message.error('纳税号填写格式有误')
        } else {
          rule = true
        }
      }
      if (phone) {
        if (!phoneNumber.test(phone)) {
          rule = false
          this.$message.error('手机号号填写格式有误')
        } else {
          rule = true
        }
      }
      return rule
    },
    async handleSubmitInfo() {
      let params = {
        order_id: this.vipInfo.order_id,
        type: this.vipInfo.goods_type
      }
      // 已有企业
      if (this.activeName === 'first') {
        const obj = this.NoAuthorizedList.filter(m => m.conuser_id === this.conuser_id)
        for (const key in this.firmFormInfo) {
          this.firmFormInfo[key] = obj[0][key]
        }
        params = Object.assign(params, this.firmFormInfo)
      } else {
        // 新增企业
        await this.addContract()
        params = Object.assign(params, this.ruleForm)
      }
      try {
        const res = await authInfoForCompany(params)
        if (res.code === 0) {
          this.$message.success('提交成功')
          this.$emit('success', {
            type: 2,
            contract_id: res.data.contract_id
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 新增企业
    async addContract() {
      try {
        const res = await fetchAddContractUser(this.getAddParams())
        if (res.code === 0) {
          this.ruleForm.conuser_id = res.data.conuser_id
        }
      } catch (error) {
        console.log(error)
      }
    },
    getAddParams() {
      const params = {
        type: this.vipInfo.goods_type,
        ...this.ruleForm
      }
      return params
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-wrap {
  .vip-info {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0;
    padding-left: 24px;

    li {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 40px;
      margin-bottom: 8px;
      white-space: nowrap;
    }

    .rowShow {
      display: flex;

      .firmlist {
        display: flex;
        flex-wrap: wrap;

        .name {
          margin: 0 24px 4px 0;
        }
      }
    }
  }

  .tab-wrap {
    border: 1px solid #F2F2F2;

    /deep/.header {
      height: 48px;

      .el-tabs__header {
        margin: 0;

        .el-tabs__nav-wrap::after {
          height: 1px;
          background-color: #F2F2F2;
        }

        .el-tabs__active-bar {
          height: 3px;
        }
      }

      .el-tabs__nav {
        height: 48px;
        margin-left: 16px;
        display: flex;
        align-items: center;

        .el-tabs__item {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  /deep/.radio-container {
    padding: 25px 16px;

    .el-radio {
      margin-bottom: 19px;

      .el-radio__input {
        @include size(16px, 16px);
      }

      .el-radio__label {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .form-container {
    padding: 4px 10px 4px 16px;
    max-height: 191px;
    overflow-y: scroll;

    .tip {
      @include size(100%, 32px);
      background: rgba(255, 142, 20, 0.1);
      border: 1px solid #FF8E14;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      padding-left: 24px;
      margin: 4px 0 15px;

      i {
        color: #FF8E14;
        margin-right: 4px;
      }
    }

    /deep/.el-form {
      .el-form-item {
        margin-bottom: 15px;
      }

      .is-required {
        .el-form-item__label {
          padding-left: 0;
        }
      }

      .el-form-item__label {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        text-align: left;
        padding-left: 6.08px;
      }

      .el-form-item__content {
        height: 32px;
      }

      .el-input {
        height: 100%;

        input {
          height: 100%;
          background: #F5F6FA;
          border-radius: 2px;
          border: none;

          &::placeholder {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }

  .redText {
    color: $theme-color;
    font-size: 12px;
  }
}
</style>