<template>
  <div class="member-wrap container_media">
    <div class="rowShow title-row">
      <p class="card-title">我的会员</p>
      <ul class="tab-model">
        <li :class="activeName === item.value ? 'tab active' : 'tab'" v-for="(item, idx) in tabList" :key="idx"
          @click="handleTabClick(item)">
          <span v-if="item.value === 'person'">{{ `${item.label} [${grTotal}]` }}</span>
          <span v-else>{{ `${item.label} [${qyTotal}]` }}</span>
        </li>
      </ul>
    </div>
    <div class="content myCenter_table">
      <el-table ref="multipleTable" :data="activeName === 'person' ? person_list : enterprise_list"
        header-cell-class-name="table_header" :cell-style="TableRowStyle">
        <el-table-column label="序号" type="index" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 + pageParams.page_size * (pageParams.page - 1) }}</template>
        </el-table-column>
        <el-table-column label="会员名称" prop="goods_name" align="center" />
        <!-- <el-table-column label="类型" prop="type" align="center">
          <template slot-scope="{ row }">
            {{ row.goods_type == '1' ? '个人' : '企业' }}
          </template>
        </el-table-column> -->
        <el-table-column label="有效期" prop="type" align="left" width="200">
          <template slot-scope="{ row: { start_at, end_at, status } }">
            <span v-if="status">
              {{ start_at.replace(/-/g, '/') | parseDate('yyyy.MM.dd') }} —
              {{ end_at.replace(/-/g, '/') | parseDate('yyyy.MM.dd') }}
            </span>
            <span v-else> - </span>
          </template>
        </el-table-column>
        <el-table-column label="会员状态" align="left" >
          <template slot-scope="{ row: { end_at } }">
            <span :class="validTime(end_at) ? 'valid' : 'novalid'">{{ validTime(end_at) ? '有效' : '已到期' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="协议状态" align="center">
          <!-- rel_num -->
          <template slot-scope="{ row: { contract_num, end_at,  } }">
            <span class="activate" v-if="contract_num > 0"><i class="el-icon-success"></i>已签署</span>
            <span class="noActivate"
              v-if="contract_num === 0 && new Date(end_at).getTime() > new Date().getTime()"><i class="el-icon-warning"></i>待签署</span>
            <span class="closeStatus" v-if="contract_num == 0 && new
              Date(end_at).getTime() < new Date().getTime()"><i class="el-icon-warning"></i>已关闭</span>
          </template>
        </el-table-column>
        <el-table-column v-if="activeName === 'enterprise'" align="center">
          <template slot="header">
            <span>可授权公司
              <el-tooltip class="item" effect="dark" content="（已授权公司数/可授权公司数）" placement="top">
                <img class="question" src="@/assets/svg/question-icon.svg" alt="">
              </el-tooltip></span>
          </template>
          <template slot-scope="{ row: { contract_num, cert_num } }">
            {{ `${contract_num}/${cert_num}` }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="支付状态" prop="type" align="center">
          <template slot-scope="{ row: { status, pay_type } }">
            <p v-if="pay_type === 'REGISTER_PAY'">活动赠送</p>
            <p v-else-if="pay_type === 'WX_SEND_PAY'">系统赠送</p>
            <p v-else-if="pay_type === 'WX_ACTIVE_PAY'">活动赠送</p>
            <p v-else>{{ (status + '') | transitionValToLabel(orderPayStatus) }}</p>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" align="left" width="220">
          <template
            slot-scope="{ row: { order_id, status, goods_type, goods_use_mode, end_at, contract_num, contract_id, pay_type, cert_num, rel_num, is_month, is_sum, use_type } }">
            <div class="op-wrap">
              <el-button class="detail-text" type="text" :disabled="!(status === 4)" @click="handleViewDetail(order_id)">
                查看详情
              </el-button>
              <p class="cut-off-rule"></p>
              <template v-if="goods_type === 1">
                <el-button :class="!validTime(end_at) ? 'disabled-text' : 'detail-text'" type="text"
                  :disabled="new Date(end_at).getTime() < new Date().getTime()"
                  @click="handleSign(goods_type, order_id, end_at)" v-if="contract_num === 0">签署协议</el-button>
                <el-button class="detail-text" type="text" v-else @click="checkPdf(contract_id)">查看协议</el-button>
              </template>
              <template v-else>
                <el-button :class="new Date(end_at).getTime() < new Date().getTime() ? 'disabled-text' : 'detail-text'"
                  type="text" :disabled="!validTime(end_at)" @click="handleSign(goods_type, order_id, end_at)"
                  v-if="cert_num > contract_num && validTime(end_at)">签署协议</el-button>
                <el-button class="detail-text" type="text" v-if="contract_num === rel_num && contract_num === cert_num"
                  @click="handleImpowerDetail(order_id)">授权详情</el-button>
              </template>
              <p class="cut-off-rule"></p>
              <el-button class="detail-text" type="text"
                v-if="!(is_month == 1 || is_sum == 3)"
                :class="(use_type == 3 || goods_use_mode === 3 || !(status === 4) || pay_type === 'EXCHANGE' || new Date(end_at).getTime() < new Date().getTime()) ? 'disabled-text' : 'detail-text'"
                :disabled="use_type == 3 || goods_use_mode === 3 || !(status === 4) || pay_type === 'EXCHANGE' || new Date(end_at).getTime() < new Date().getTime()"
                @click="handleUpMember(order_id, goods_type)">
                升级会员
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="vf-music-pagination">
        <Pagination v-if="total > 0" :hiddenPage="true" position="center" :limit.sync="pageParams.page_size"
          :page.sync="pageParams.page" @pagination="pagination" :total="total" />
      </div>
    </div>
    <!-- 签署协议弹窗 -->
    <ratifyAccord ref="ratifyAccordRef" @getVipsList="getVipsList"></ratifyAccord>
    <!-- pdf -->
    <PurchaseProtocol v-if="contract_id" ref="PurchaseProtocol" />
  </div>
</template>

<script>
import { ORDER_PAY_STATUS, VIP_TYPE } from '@/enum/common'
import Equity from '@/views/member/equity/mixins/equity'
import { fetchUserVipsOrderList } from '@/api/user'
import ratifyAccord from '../dialog/ratifyAccord.vue'
import Pagination from '@/components/Pagination'
import PurchaseProtocol from '@/components/VfPdf/PurchaseProtocol'
import { fetchMemberContractPdf } from '@/api/member'

export default {
  name: 'Equity',
  components: {
    ratifyAccord,
    Pagination,
    PurchaseProtocol
  },
  data() {
    return {
      person_list: [],
      enterprise_list: [],
      tabList: [{
        label: `个人会员`,
        value: 'person'
      }, {
        label: `企业会员`,
        value: 'enterprise'
      }],
      activeName: 'person',
      pageParams: {
        page: 1,
        page_size: 10
      },
      total: 0,
      qyTotal: 0,
      grTotal: 0,
      contract_id: '',
      vipType: [...Object.values(VIP_TYPE)],
      orderPayStatus: [...Object.values(ORDER_PAY_STATUS)]
    }
  },
  mixins: [Equity],
  created() {
    if (JSON.stringify(this.$route.query) !== '{}' && this.$route.query.goods_type) {
      const { page, goods_type } = this.$route.query
      this.pageParams.page = Number(page)
      this.getVipsList(Number(goods_type) === 1 ? 2 : 1, true)
      this.getVipsList(Number(goods_type))
    } else {
      this.getVipsList(2)
      this.handleTabClick()
    }
  },
  methods: {
    // 是否有效期
    validTime(time) {
      let flag = true
      if (new Date(time).getTime() > new Date().getTime()) {
        flag = true
      } else {
        flag = false
      }
      return flag
    },
    pagination(info) {
      this.pageParams.page = info.page
      this.pageParams.page_size = info.limit
      this.getVipsList(this.activeName === 'person' ? 1 : 2)
    },
    async checkPdf(contract_id) {
      this.contract_id = contract_id
      await fetchMemberContractPdf(contract_id)
      // this.$nextTick(() => {
      //   this.$refs.PurchaseProtocol.initPDFPage(contract_id)
      // })
    },
    // 签署协议
    handleSign(type, order_id, end_at) {
      if (new Date(end_at).getTime() < new Date().getTime()) return
      this.$refs.ratifyAccordRef.showDia(type, order_id)
    },
    handleTabClick(item) {
      this.pageParams.page = 1
      this.activeName = item ? item.value : 'person'
      this.getVipsList(this.activeName === 'person' ? 1 : 2)
    },
    // 获取会员列表
    getVipsList(goodsType = 1, noChangeTab = false) {
      if (!noChangeTab) {
        goodsType === 1 ? this.activeName = 'person' : this.activeName = 'enterprise'
      }
      const params = {
        goods_type: goodsType,
        ...this.pageParams
      }
      this.$root.$goSearch(params)
      fetchUserVipsOrderList(params).then((res) => {
        if (res.code === 0) {
          const { data } = res
          if (goodsType === 1) {
            this.person_list = data.list
            this.grTotal = data.total
          } else {
            this.enterprise_list = data.list
            this.qyTotal = data.total
          }
          this.total = data.total
        }
      })
    },
    // 跳转至详情
    handleViewDetail(id) {
      console.log(this.$route.query)
      this.$store.dispatch('user/setVipRouteInfo', this.$route.query)
      this.$router.push({
        path: `/member/center/equity/detail/${id}`
      })
    },
    handleImpowerDetail(id) {
      this.$router.push({
        path: `/member/center/equity/impowerDetail/${id}`
      })
    },
    TableRowStyle({ rowIndex }) {
      let tableRowStyle = "";
      if (rowIndex % 2 !== 0) {
        tableRowStyle = tableRowStyle + "background-color: #FAFAFA;border-radius: 2px;";
      }
      return tableRowStyle;
    },
  }
}
</script>

<style lang="scss" scoped>
.el-table {
  .question {
    @include size(12px, 12px);
    margin-left: 4px;
  }

  .noActivate,
  .activate,
  .closeStatus {
    display: block;
    width: 62px;
    height: 24px;
    background: rgba(255, 142, 20, 0.1);
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FF8E14;
    margin: 0 auto;
    text-align: center;

    i {
      margin-right: 2px;
    }
  }

  .activate {
    background: rgba(0, 173, 118, 0.1);
    color: #00AD76;
  }

  .closeStatus {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.5);
  }
}

.rowShow {
  display: flex;
  align-items: center;
}

.member-wrap {
  margin-top: 30px;

  .card-title {
    margin-right: 40px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }

  .title-row {
    margin: 50px 0 40px;
  }

  .tab-model {
    display: flex;
    align-items: center;
    cursor: pointer;

    .tab {
      // width: 92px;
      padding: 0 18px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 20px;
    }

    .active {
      background: rgba(229, 42, 13, 0.1);
      font-weight: 600;
      color: #E52A0D;
    }
  }

  /deep/.content {
    @include size(100%, auto);
    padding: 24px 36px;
    border-radius: 4px;
    box-sizing: border-box;
    background: #FFFFFF;
    margin-top: 24px;

    .vf-music-pagination {
      margin-top: 40px;
    }
  }
}
</style>
